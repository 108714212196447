<template>
  <v-container
    fill-height
    fluid
    style="background: url('/img/grey-bg.jpg') center center fixed repeat; background-size: cover"
    class="align-start justify-start pa-2 pa-sm-6 pa-md-12"
  >
    <v-form
      ref="form"
      v-model="formValid"
    >
      <v-container
        v-if="editItem"
        fluid
        class="white--text font-weight-bold text-subtitle-2 text-sm-subtitle-1 text-md-h6"
      >
        <v-row>
          <v-col
            cols="12"
            cl
          >
            <div class="text-subtitle-1 text-sm-h6 text-md-h5 font-weight-bold">
              {{ $t('Reviews.Title') }}
            </div>
            <div class="font-weight-regular">
              {{ $t('Reviews.SubTitle') }}
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <div>
              <div>{{ $t('Reviews.Questions.Q1.Number') }}. {{ $t('Reviews.Questions.Q1.Question') }}</div>
              <v-radio-group
                v-model="editItem.Q1"
                :rules="validator.vuetifyFormFieldRules('Q1')"
                dark
                row
              >
                <template v-for="radio in 5">
                  <v-radio
                    :key="`Q1-RADIO-${radio}`"
                    :label="String(radio)"
                    :value="radio"
                  />
                </template>
              </v-radio-group>
            </div>

            <div>
              <div>{{ $t('Reviews.Questions.Q2.Number') }}. {{ $t('Reviews.Questions.Q2.Question') }}</div>
              <v-radio-group
                v-model="editItem.Q2"
                :rules="validator.vuetifyFormFieldRules('Q2')"
                dark
                row
              >
                <template v-for="radio in 5">
                  <v-radio
                    :key="`Q2-RADIO-${radio}`"
                    :label="String(radio)"
                    :value="radio"
                  />
                </template>
              </v-radio-group>
            </div>

            <div>
              <div>{{ $t('Reviews.Questions.Q3.Number') }}. {{ $t('Reviews.Questions.Q3.Question') }}</div>
              <v-radio-group
                v-model="editItem.Q3"
                :rules="validator.vuetifyFormFieldRules('Q3')"
                dark
                row
              >
                <template v-for="radio in 5">
                  <v-radio
                    :key="`Q3-RADIO-${radio}`"
                    :label="String(radio)"
                    :value="radio"
                  />
                </template>
              </v-radio-group>
            </div>

            <div>
              <div>{{ $t('Reviews.Questions.Q4.Number') }}. {{ $t('Reviews.Questions.Q4.Question') }}</div>
              <v-radio-group
                v-model="editItem.Q4"
                :rules="validator.vuetifyFormFieldRules('Q4')"
                dark
                row
              >
                <template v-for="radio in 5">
                  <v-radio
                    :key="`Q4-RADIO-${radio}`"
                    :label="String(radio)"
                    :value="radio"
                  />
                </template>
              </v-radio-group>
            </div>
          </v-col>

          <v-col cols="12">
            <v-btn
              color="black"
              class="font-weight-bold"
              min-width="225"
              depressed
              x-large
              dark
              @click="onNextClick"
            >
              {{ $t('Common.Button.Next') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </v-container>
</template>

<script>
import dialogData  from '../../mixins/dialogData'
import ReviewModel from '../../api/mixins/models/ReviewModel'

export default {
  name      : 'Step1',
  components: {},
  directives: {},
  mixins    : [ReviewModel, dialogData],
  props     : [],
  data () {
    return {
      formValid: false
    }
  },
  computed: {},
  watch   : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {
    this.init(true)
  },
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    onNextClick () {
      this.$refs.form.validate()

      if (this.formValid) {
        this.$router.replace({ name: 'Step2', params: { item: this.editItem } })
      }
    }
  }
}
</script>

<style scoped>

</style>
