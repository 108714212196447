<template>
  <v-app>
    <v-app-bar
      app
      dark
      color="black"
      src="/img/header-line.png"
      height="120"
    >
      <img
        class="pl-4 mt-2"
        width="300"
        src="/img/driver-center-logo.png"
      >
    </v-app-bar>

    <v-main>
      <slot />
    </v-main>

    <v-footer
      dark
      padless
      height="250"
      color="black"
    >
      <v-img
        :src="footerImage"
        height="250"
      />
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name      : 'QuestionsLayout',
  components: {},
  mixins    : [],
  data () {
    return {}
  },
  computed: {
    footerImage () {
      if (this.$route.name === 'Step1') return '/img/img-bg-1.jpg'
      if (this.$route.name === 'Step2') return '/img/img-bg-2.jpg'
      if (this.$route.name === 'Step3') return '/img/img-bg-3.jpg'
      return '/img/img-bg-1.jpg'
    }
  },
  watch  : {},
  created () {},
  mounted () {},
  updated () {},
  beforeDestroy () {},
  methods: {}
}
</script>

<style>

</style>
