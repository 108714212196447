import { API, APICall } from '../Api'

export default {
  name: 'Reviews',

  methods: {
    async save (data) {
      if (typeof this.clearServerErrorMessages === 'function') this.clearServerErrorMessages()
      const Request = {
        Type    : APICall.Review.Save.Type,
        Endpoint: APICall.Review.Save.Endpoint,
        Data    : data
      }
      return await API[Request.Type](Request.Endpoint, Request.Data)
    }
  }

}
