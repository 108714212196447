import Vue from 'vue'

const DataStore = Vue.observable({

  App: {
    version: process.env.VUE_APP_VERSION
  },
  Areas  : [],
  Dealers: []
})

export default DataStore
