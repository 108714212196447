<template>
  <v-container
    fill-height
    fluid
    style="background: url('/img/grey-bg.jpg') center center fixed repeat; background-size: cover"
    class="align-start justify-start pa-2 pa-sm-6 pa-md-12"
  >
    <v-form
      ref="form"
      v-model="formValid"
    >
      <v-container
        v-if="editItem"
        fluid
        class="white--text font-weight-bold text-subtitle-2 text-sm-subtitle-1 text-md-h6"
      >
        <v-row>
          <v-col cols="12">
            <div>
              <div>{{ $t('Reviews.Questions.Q5.Number') }}. {{ $t('Reviews.Questions.Q5.Question') }}</div>
              <v-radio-group
                v-model="editItem.Q5"
                :rules="validator.vuetifyFormFieldRules('Q5')"
                dark
                row
              >
                <template v-for="radio in 5">
                  <v-radio
                    :key="`Q5-RADIO-${radio}`"
                    :label="String(radio)"
                    :value="radio"
                  />
                </template>
              </v-radio-group>
            </div>

            <div>
              <div>{{ $t('Reviews.Questions.Q6.Number') }}. {{ $t('Reviews.Questions.Q6.Question') }}</div>
              <v-radio-group
                v-model="editItem.Q6"
                :rules="validator.vuetifyFormFieldRules('Q6')"
                dark
                row
              >
                <template v-for="radio in 5">
                  <v-radio
                    :key="`Q6-RADIO-${radio}`"
                    :label="String(radio)"
                    :value="radio"
                  />
                </template>
              </v-radio-group>
            </div>

            <div>
              <div>{{ $t('Reviews.Questions.Q7.Number') }}. {{ $t('Reviews.Questions.Q7.Question') }}</div>
              <v-radio-group
                v-model="editItem.Q7"
                :rules="validator.vuetifyFormFieldRules('Q7')"
                dark
                row
              >
                <template v-for="radio in 5">
                  <v-radio
                    :key="`Q7-RADIO-${radio}`"
                    :label="String(radio)"
                    :value="radio"
                  />
                </template>
              </v-radio-group>
            </div>

            <div>
              <div>{{ $t('Reviews.Questions.Q8.Number') }}. {{ $t('Reviews.Questions.Q8.Question') }}</div>
              <v-textarea
                v-model="editItem.Q8"
                :rules="validator.vuetifyFormFieldRules('Q8')"
                auto-grow
                solo
                flat
                style="max-width: 1080px"
              />
            </div>

            <!--<div>
              <v-switch
                v-model="editItem.Terms"
                :rules="validator.vuetifyFormFieldRules('Terms')"
                color="primary"
                inset
              >
                <template v-slot:label>
                  <div class="text&#45;&#45;white white&#45;&#45;text">
                    {{ $t('Home.Form.Fields.Terms.Label') }}
                    <a
                      href="#"
                      @click.stop="onTermsClick"
                      v-text="$t('Home.Form.Fields.Terms.Single')"
                    />
                    {{ $t('Home.Form.Fields.Terms.Hint') }}
                  </div>
                </template>
              </v-switch>
            </div>-->
          </v-col>

          <v-col cols="12">
            <v-btn
              color="black"
              class="font-weight-bold"
              min-width="225"
              :disabled="isSaving"
              :loading="isSaving"
              depressed
              x-large
              dark
              @click="onNextClick"
            >
              {{ $t('Common.Button.Send') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>

    <terms-view-dialog :visible.sync="termsDialogVisible" />
  </v-container>
</template>

<script>
import dialogData      from '../../mixins/dialogData'
import ReviewModel     from '../../api/mixins/models/ReviewModel'
import Reviews         from '../../api/mixins/Reviews'
import TermsViewDialog from '../../components/common/TermsViewDialog'

export default {
  name      : 'Step2',
  components: { TermsViewDialog },
  directives: {},
  mixins    : [Reviews, ReviewModel, dialogData],
  props     : [],
  data () {
    return {
      formValid         : false,
      termsDialogVisible: false
    }
  },
  computed: {},
  watch   : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {
    this.init(true)
  },
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    onNextClick () {
      this.$refs.form.validate()

      if (this.formValid) {
        this.isSaving = true

        this.save(this.editItem)
          .then(response => {
            if (response.data.status === 'success') {
              if (response.data.data === true) {
                this.$router.replace({ name: 'Step3', params: { item: this.editItem } })
              } else {
                this.formSaveError(response.data)
              }
            } else {
              this.setServerErrorMessages(response.data.data)
            }
          })
          .catch(error => {
            this.formSaveError(error)
          })
          .finally(() => {
            this.isSaving = false
          })
      }
    },

    onTermsClick (e) {
      e.preventDefault()
      this.termsDialogVisible = true
    }
  }
}
</script>

<style scoped>

</style>
