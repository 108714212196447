import Vue                       from 'vue'
import Storage                   from 'vue-web-storage'
import VueMeta                   from 'vue-meta'
import App                       from './App.vue'
import router                    from './router'
import vuetify                   from './plugins/vuetify'
import { API, APICall }          from './api/Api'
import { i18n, setI18nLanguage } from './lang/lang'
import DataStorePlugin           from './lib/data/DataStore/Plugin/DataStorePlugin'
import EventBus                  from './lib/events/eventBus'
import DefaultLayout             from './views/layouts/DefaultLayout'
import HomeLayout                from './views/layouts/HomeLayout'
import QuestionsLayout           from './views/layouts/QuestionsLayout'
import DataStore                 from '@/api/DataStore/DataStore'

Vue.config.productionTip = false
window.API = API
window.APICall = APICall

window.initVUE = () => {
  Vue.component('default-layout', DefaultLayout)
  Vue.component('home-layout', HomeLayout)
  Vue.component('questions-layout', QuestionsLayout)

  Vue.use(EventBus)
  Vue.use(DataStorePlugin)
  Vue.use(Storage, {
    prefix : 'pdhr_',
    drivers: ['session', 'local']
  })
  Vue.use(VueMeta, {
    keyName                : 'metaInfo',
    attribute              : 'data-vue-meta',
    ssrAttribute           : 'data-vue-meta-server-rendered',
    tagIDKeyName           : 'vmid',
    refreshOnceOnNavigation: true
  })

  window.Vue = new Vue({
    el        : '#app',
    directives: {},
    mixins    : [],
    data () {
      return {}
    },
    beforeMount () {
      setI18nLanguage(process.env.VUE_APP_I18N_LOCALE)
    },
    created () {
      let dealerId = ''

      if (this.$route && this.$route.query.d) {
        dealerId = String(this.$route.query.d).trim().toLowerCase()
      } else if (this.$sessionStorage.get('d')) {
        dealerId = String(this.$sessionStorage.get('d', '')).trim().toLowerCase()
      }

      if (dealerId) {
        this.$sessionStorage.set('d', dealerId)
        if (String(this.$route.query.d).trim().toLowerCase()) this.$router.replace({ name: 'Home' })
      } else {
        this.$sessionStorage.remove('d')
      }
    },
    mounted () {},
    beforeDestroy () {},
    methods: {},
    i18n,
    router,
    vuetify,
    render : h => h(App)
  })

  if (process.env.NODE_ENV === 'production') {
    Vue.config.productionTip = false
    Vue.config.devtools = false
    Vue.config.debug = false
    Vue.config.silent = true
  }
}

const GetConfig = () => {
  const Request = {
    Type    : APICall.Settings.Get.Type,
    Endpoint: APICall.Settings.Get.Endpoint,
    Data    : {}
  }
  API[Request.Type](Request.Endpoint, Request.Data)
    .then(response => {
      if (response.status === 200 && response.data) {
        DataStore.Areas = response.data?.Areas || []
        DataStore.Dealers = response.data?.Dealers || []
      } else {
        DataStore.MaintenanceMode = true
      }
      window.initVUE()
    })
    .catch(e => {})
    .finally(() => {})
}

GetConfig()
