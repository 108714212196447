import axios               from 'axios'
import { i18n, languages } from '@/lang/lang'

export const API = axios.create({
  baseURL        : process.env.VUE_APP_API_URL || '',
  timeout        : parseInt(process.env.VUE_APP_API_TIMEOUT) || 300000,
  responseType   : 'json',
  crossDomain    : true,
  withCredentials: true,
  headers        : {
    'X-Requested-With': 'XMLHttpRequest',
    'X-AppVersion'    : process.env.VUE_APP_VERSION || ''
  }
})

API.interceptors.request.use((request) => {
  request.headers['Accept-Language'] = languages.find(l => l.locale === i18n.locale).code
  return request
}, function (error) {
  return Promise.reject(error)
})

API.interceptors.response.use((response) => {
  return response
}, function (error) {
  return Promise.reject(error)
})

export const APICall = {
  Review: {
    Save: {
      Endpoint: 'reviewAdd',
      Type    : 'post'
    }
  },

  Settings: {
    Get: {
      Endpoint: 'reviewSettings',
      Type    : 'post'
    }
  }
}
