import Vue     from 'vue'
import Vuetify from 'vuetify/lib'
import el      from 'vuetify/es5/locale/el'
import en      from 'vuetify/es5/locale/en'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    disable: false,
    dark   : false,
    themes : {
      dark: {
        anchor   : '#1976d2',
        primary  : '#1976d2',
        secondary: '#424242',
        accent   : '#82B1FF',
        error    : '#FF5252',
        info     : '#2196F3',
        success  : '#4CAF50',
        warning  : '#FFC107',
        pred     : '#da251e',
        pyellow  : '#FFDD00'
      },
      light: {
        anchor   : '#1976d2',
        primary  : '#1976d2',
        secondary: '#424242',
        accent   : '#82B1FF',
        error    : '#FF5252',
        info     : '#2196F3',
        success  : '#4CAF50',
        warning  : '#FFC107',
        pred     : '#da251e',
        pyellow  : '#FFDD00'
      }
    }
  },
  lang: {
    locales: {
      el,
      en
    },
    current: process.env.VUE_APP_I18N_LOCALE
  },
  icons: {
    iconfont: 'mdi'
  }
})
