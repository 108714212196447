<template>
  <div>
    <v-app-bar v-bind="{...$props, ...$attrs}">
      <v-toolbar-title
        v-if="titleVisible"
        class="hidden-xs-only body-2 pl-1"
      >
        <slot name="title">
          {{ title }}
        </slot>
      </v-toolbar-title>

      <div class="flex-grow-1" />

      <img
        v-if="logoVisible"
        class="pl-2"
        height="50"
        src="/img/driver-center-logo.png"
      >
      <div class="hidden-sm-and-up flex-grow-1" />
    </v-app-bar>
  </div>
</template>

<script>
export default {
  name      : 'AppToolbar',
  components: {},
  directives: {},
  mixins    : [],
  props     : {
    titleVisible: {
      type   : Boolean,
      default: false
    },
    logoVisible: {
      type   : Boolean,
      default: true
    }
  },
  data () {
    return {}
  },
  computed: {
    title () {
      const key = `${ this.$route.name }.Title`
      return this.$te(key) ? this.$t(key) : this.$t('Common.App.Title')
    }
  },
  watch  : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {}
}
</script>

<style scoped>

</style>
