<template>
  <v-footer v-bind="{...$props, ...$attrs}">
    <v-col
      class="text-center"
      cols="12"
    >
      {{ new Date().getFullYear() }} &copy;
      <span class="font-weight-bold pr-1">{{ siteCopyRightTitle }}</span>
      <span class="font-weight-thin">HELLAS</span>
    </v-col>
  </v-footer>
</template>

<script>

export default {
  name      : 'AppFooter',
  components: {},
  directives: {},
  mixins    : [],
  props     : {},
  data () {
    return {}
  },
  computed: {
    siteCopyRightTitle () {
      return this.$t('Common.App.Title')
    }
  },
  watch  : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {}
}
</script>

<style scoped>

</style>
