<template>
  <v-container
    fill-height
    fluid
    style="background: url('/img/grey-bg.jpg') center center fixed repeat; background-size: cover"
    class="align-start justify-start pa-2 pa-sm-6 pa-md-12"
  >
    <v-container
      v-if="editItem"
      fluid
      class="white--text font-weight-bold text-subtitle-2 text-sm-subtitle-1 text-md-h6"
    >
      <v-row>
        <v-col
          cols="12"
          sm="8"
          md="7"
          lg="5"
        >
          <p>{{ $t('Home.Intro.Title') }}</p>
          <p>
            {{ $t('Home.Intro.SubTitle') }}
            <br>
            {{ $t('Home.Intro.Text') }}
          </p>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          cols="12"
          sm="8"
          md="7"
          lg="5"
        >
          <template v-if="!dealerFromQR">
            <div class="pb-4">
              {{ $t('Home.Form.Title') }}
            </div>
            <v-autocomplete
              v-model="selectedArea"
              :items="dealerAreas"
              :rules="[]"
              :label="$t(`Home.Form.Fields.AreaId.Label`)"
              solo
              flat
              clearable
              item-text="Name"
              item-value="Id"
              color="black"
            />

            <v-autocomplete
              v-model="editItem.DealerId"
              :items="dealersList"
              :rules="[]"
              :label="$t(`Home.Form.Fields.DealerId.Label`)"
              :disabled="!selectedArea"
              solo
              flat
              clearable
              item-text="Name"
              item-value="Id"
              color="black"
            />
          </template>

          <v-btn
            color="black"
            class="font-weight-bold"
            min-width="225"
            depressed
            x-large
            dark
            :disabled="!editItem.DealerId"
            @click="onNextClick"
          >
            {{ $t('Common.Button.Next') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import dialogData  from '../../mixins/dialogData'
import ReviewModel from '../../api/mixins/models/ReviewModel'

export default {
  name      : 'Home',
  components: {},
  directives: {},
  mixins    : [ReviewModel, dialogData],
  props     : [],
  data () {
    return {
      selectedArea      : null,
      termsDialogVisible: false
    }
  },
  computed: {
    dealerAreas () {
      return this.$DataStore.Areas
    },

    dealersList () {
      return this.$DataStore.Dealers.filter(d => d.AreaId === this.selectedArea)
    },

    dealerFromQR () {
      return this.$sessionStorage.get('d', '')
    }
  },
  watch: {},
  beforeCreate () {},
  created () { },
  beforeMount () {},
  mounted () {
    this.init(true)
    this.$nextTick(() => {
      if (this.dealerFromQR) {
        this.editItem.DealerId = parseInt(this.dealerFromQR)
      }
    })
  },
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    onNextClick () {
      this.$router.replace({ name: 'Step1', params: { item: this.editItem } })
    }
  }
}
</script>

<style scoped>

</style>
