<template>
  <v-app>
    <v-app-bar
      app
      dark
      color="black"
      height="120"
    >
      <div
        style="width: 100%;"
        class="text-h6 text-sm-h5 text-lg-h4 text-center align-center justify-center"
        v-html="$t('Home.Header.Title').toLocaleUpperCase($i18n.locale)"
      />
    </v-app-bar>

    <v-main>
      <slot />
    </v-main>

    <v-footer
      dark
      padless
      height="150"
      color="black"
    >
      <v-img
        src="/img/header-line.png"
        style="transform: rotate(180deg); transform: scaleX(-1);"
        position="top center"
        height="150"
      />
      <img
        class="pl-4 mb-6"
        width="300"
        src="/img/driver-center-logo.png"
        style="position: absolute;"
      >
      <img
        class="hidden-xs-only"
        src="/img/tyre.png"
        style="position: absolute; right: 0px; bottom: 0px;"
        :style="'width: 225px;'"
      >
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name      : 'HomeLayout',
  components: {},
  mixins    : [],
  data () {
    return {}
  },
  computed: {},
  watch   : {},
  created () {},
  mounted () {},
  updated () {},
  beforeDestroy () {},
  methods : {}
}
</script>

<style>

</style>
