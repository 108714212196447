<template>
  <v-app>
    <app-toolbar
      app
      clipped-left
      color="secondary"
      dark
    />

    <v-main>
      <slot />
    </v-main>

    <app-footer
      app
      dark
      padless
    />
  </v-app>
</template>

<script>
import AppToolbar from '../../components/app/AppToolbar'
import AppFooter  from '../../components/app/AppFooter'

export default {
  name      : 'DefaultLayout',
  components: { AppToolbar, AppFooter },
  mixins    : [],
  data () {
    return {}
  },
  computed: {},
  watch   : {},
  created () {},
  mounted () {},
  updated () {},
  beforeDestroy () {},
  methods : {}
}

</script>

<style>

</style>
