export default {

  App: {
    Title: 'DRIVER CENTER'
  },

  Search: {
    Title: 'Αναζήτηση'
  },

  Text: {
    And      : 'και',
    Unlimited: 'Απεριόριστα',
    From     : 'Από',
    To       : 'Έως'
  },

  Messages: {
    Loading: {
      Title   : 'Φόρτωση',
      Wait    : 'Παρακαλώ Περιμένετε',
      Progress: '%{progress}%'
    }
  },

  Date: {
    Title     : 'Ημερομηνία | Ημερομηνίες',
    Today     : 'Σήμερα',
    Day       : 'Ημέρα | Ημέρες',
    Week      : 'Εβδομάδα | Εβδομάδες',
    Month     : 'Μήνας | Μήνες',
    Year      : 'Έτος | Έτη',
    Period    : 'Περίοδος | Περίοδοι',
    PeriodFrom: 'Από',
    PeriodTo  : 'Έως'
  },

  Month: {
    1 : 'Ιανουάριος',
    2 : 'Φεβρουάριος',
    3 : 'Μάρτιος',
    4 : 'Απρίλιος',
    5 : 'Μάιος',
    6 : 'Ιούνιος',
    7 : 'Ιούλιος',
    8 : 'Αύγουστος',
    9 : 'Σεπτέμβριος',
    10: 'Οκτώβριος',
    11: 'Νοέμβριος',
    12: 'Δεκέμβριος'
  },

  Button: {
    Save         : 'Αποθήκευση',
    Update       : 'Ενημέρωση',
    View         : 'Προβολή',
    ViewAll      : 'Προβολή Όλων',
    New          : 'Δημιουργία',
    Create       : 'Δημιουργία',
    Entry        : 'Καταχώρηση',
    Edit         : 'Επεξεργασία',
    Export       : 'Εξαγωγή',
    Import       : 'Εισαγωγή',
    Delete       : 'Διαγραφή',
    Remove       : 'Διαγραφή',
    Login        : 'Σύνδεση',
    Cancel       : 'Ακύρωση',
    Close        : 'Κλείσιμο',
    Send         : 'Αποστολή',
    Download     : 'Κατέβασμα',
    Continue     : 'Συνέχεια',
    CreateAndSend: 'Δημιουργία & Αποστολή Email',
    SaveAndSend  : 'Αποθήκευση & Αποστολή Email',
    Yes          : 'Ναί',
    No           : 'Όχι',
    Ok           : 'Οκ',
    Next         : 'Επόμενο',
    Prev         : 'Προηγούμενο'
  },

  Error: {
    Generic             : 'Υπήρξε κάποιο πρόβλημα. Παρακαλώ προσπαθήστε ξανά.',
    Staging             : 'Προσοχή! Αυτό είναι DEMO περιβάλλον.',
    Development         : 'Προσοχή! Αυτό είναι DEV περιβάλλον.',
    MaintenanceMode     : 'Αυτή τη στιγμή εκτελούνται εργασίες. Παρακαλούμε δοκιμάστε αργότερα.',
    MaintenanceModeAdmin: 'Προσοχή! Η εφαρμογή είναι σε Maintenance Mode.'
  },

  Confirm: {
    ValidateDelete: {
      Text  : 'Για διαγραφή, πληκτρολογήστε <span class="font-weight-regular error--text">{answer}</span> στο παρακάτω πλαίσιο κειμένου.',
      Answer: 'sudo delete'
    },
    ValidateCancel: {
      Text  : 'Για ακύρωση, πληκτρολογήστε <span class="font-weight-regular error--text">{answer}</span> στο παρακάτω πλαίσιο κειμένου.',
      Answer: 'sudo cancel'
    },
    ValidateUpdate: {
      Text  : 'Για ενημέρωση, πληκτρολογήστε <span class="font-weight-regular error--text">{answer}</span> στο παρακάτω πλαίσιο κειμένου.',
      Answer: 'sudo update'
    }
  },

  Misc: {
    Locale   : 'Γλώσσα',
    CreatedAt: '@:Common.Button.Create',
    UpdatedAt: '@:Common.Button.Update',
    DeletedAt: '@:Common.Button.Delete'
  }
}
