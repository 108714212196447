export default {

  Header: {
    Title: 'Παρακαλούμε αξιολογήστε<br>την επίσκεψή σας στο κατάστημά μας'
  },

  Intro: {
    Title   : 'Αγαπητέ Πελάτη',
    SubTitle: 'Ευχαριστούμε που επέλεξες τα καταστήματα DRIVER.',
    Text    : 'Θα θέλαμε μόνο δυο λεπτά από το χρόνο σου για να αξιολογήσεις την εμπειρία σου ώστε να μας δώσεις την ευκαιρία να βελτιώσουμε την εξυπηρέτηση και τις παρεχόμενες υπηρεσίες.'
  },

  Form: {
    Title : 'Βρες εδώ το κατάστημα που επισκέφτηκες',
    Fields: {
      AreaId  : {
        Label: 'ΠΟΛΗ',
        Hint : 'Επιλέξτε Πόλη'
      },
      DealerId: {
        Label: 'ΚΑΤΑΣΤΗΜΑ',
        Hint : 'Επιλέξτε Κατάστημα'
      },
      Terms   : {
        Label : 'Έχω διαβάσει & αποδέχομαι τους',
        Single: 'όρους',
        Hint  : 'χρήσης'
      }
    }
  },

  Thanks: 'Ευχαριστούμε',

  Terms: {
    Title   : 'Όροι Χρήσης',
    SubTitle: 'Αξιολόγηση DRIVER',
    Body    : `
        <p>
          <h3>ΠΡΟΩΘΗΤΙΚΗ ΕΝΕΡΓΕΙΑ TYRELIFE</h3>
          <p>Η ΕΛΑΣΤΙΚΑ PIRELLI ΑΕΕ (στο εξής “Pirelli”) και οι μεταπωλητές που συμμετέχουν στην προωθητική ενέργεια (ο κατάλογος των συμμετεχόντων μεταπωλητών δημοσιεύεται στην ιστοσελίδα: <a href="https://www.pirelli.gr/" target="_blank">www.pirelli.gr</a>) προσφέρουν με την κάρτα TYRELIFE σημαντικά οφέλη στους αγοραστές επιβατικών ελαστικών Pirelli εκτός των ελαστικών Run-Flat και των ελαστικών για ελαφρά φορτηγά. Το πρόγραμμα λειτουργεί ανεξάρτητα από τις άλλες προωθητικές ενέργειες της Pirelli και των ενεργειών των εταιρειών του γκρουπ της Pirelli.</p>
        </p>

        <p>
          <h3>ΠΛΑΙΣΙΟ ΚΑΙ ΙΣΧΥΣ</h3>
          <p>Η ενέργεια ισχύει για αγορά 4 ελαστικών Pirelli (με βάση την ανάλυση στην προηγούμενη παράγραφο), που πραγματοποιείται στην έδρα των μεταπωλητών που συμμετέχουν στην ενέργεια Tyrelife. Η ενέργεια απευθύνεται αποκλειστικά σε φυσικά πρόσωπα και εφαρμόζεται μόνο σε οχήματα ιδιωτικής χρήσης (ΙΧ) κυριότητας αυτών. Η κατοχή, χρήση ή μίσθωση (leasing) οχήματος δεν παρέχει δικαίωμα συμμετοχής στην ενέργεια. Το ενδιαφερόμενο να συμμετάσχει στην ενέργεια φυσικό πρόσωπο οφείλει να επιδεικνύει στον μεταπωλητή την άδεια κυκλοφορίας του οχήματος προκειμένου να αποδεικνύει ότι είναι ιδιοκτήτης αυτού. Μεταπωλητής, ο οποίος εγγράφει στην ενέργεια τελικό καταναλωτή δίχως να πληρούνται οι ανωτέρω προϋποθέσεις, φέρει την αποκλειστική ευθύνη έναντι του τελικού καταναλωτή. Ο τελικός καταναλωτής θα μπορεί να επωφεληθεί της ενέργειας για μέγιστο αριθμό 3 οχημάτων στο όνομά του.</p>
          <p>
          Οι μεταπωλητές που συμμετέχουν στην ενέργεια θα πραγματοποιήσουν την δωρεάν αντικατάσταση ενός ελαστικού του ιδίου τύπου και διαστάσεων, σε περίπτωση που το χρησιμοποιούμενο ελαστικό έχει υποστεί ζημιές που δεν μπορούν να επιδιορθωθούν λόγω των παρακάτω τυχαίων γεγονότων:</br>
          - κλατάρισμα</br>
          - σύγκρουση στο πεζοδρόμιο ή σε άλλο εμπόδιο</br>
          - πυρκαγιά
          </p>
          <p>Παραμένουν σε ισχύ οι εγγυήσεις του παραγωγού και του πωλητή βάσει της ισχύουσας νομοθεσίας.</p>
          <p>Η αντικατάσταση μπορεί να ζητηθεί εντός των παρακάτω ορίων και σε σχέση με τις συνθήκες που αναφέρονται στη συνέχεια: Μέχρι 12 μήνες από την ημερομηνία αγοράς. Αντικατάσταση του ελαστικού που έχει υποστεί ζημιές με ελαστικό του ιδίου τύπου και διαστάσεων. Τα κόστη τοποθέτησης και ζυγοστάθμισης του ελαστικού που αντικαταστάθηκε, θα επιβαρύνουν τον τελικό καταναλωτή.</p>
        </p>

        <p>
          <h3>ΟΡΟΙ ΓΙΑ ΤΗΝ ΑΝΤΙΚΑΤΑΣΤΑΣΗ ΤΟΥ ΕΛΑΣΤΙΚΟΥ</h3>
          <p>Θα είναι δυνατή η επίτευξη της δωρεάν αντικατάστασης του ελαστικού με τους εξής όρους:</p>
          <ol>
            <li>Οι ζημιές του ελαστικού να μην έχουν προκληθεί σκοπίμως ή κατόπιν δόλιας συμπεριφοράς.</li>
            <li>Το υπολειπόμενο πέλμα του ελαστικού να είναι μεγαλύτερο από 1,6 χιλ, το ελαστικό να έχει χρησιμοποιηθεί σωστά για τη χρήση για την οποία προορίζεται και σεβόμενοι πλήρως τους κανονισμούς και τα τεχνικά χαρακτηριστικά που υποδεικνύονται από την Pirelli και/ή από τους συμμετέχοντες στην ενέργεια Tyrelife μεταπωλητές.</li>
            <li>Ο καταναλωτής να παραδώσει το ελαστικό που έχει υποστεί ζημία στους συμμετέχοντες στην ενέργεια Tyrelife μεταπωλητές, εξουσιοδοτώντας την διάθεσή του ως απόβλητο σύμφωνα με την ισχύουσα νομοθεσία.</li>
            <li>Η κάρτα Tyrelife να μην έχει παραχωρηθεί σε τρίτους και να επιδεικνύεται κατά τη στιγμή της αντικατάστασης.</li>
            <li>Η φορολογική απόδειξη που αποδεικνύει την αγορά του ελαστικού πρέπει να διατηρηθεί και να επιδεικνύεται κατά τη στιγμή της αντικατάστασης.</li>
            <li>Το προνόμιο δωρεάν αντικατάστασης παρέχεται για ένα εκ των 4 ελαστικών που έχει αγοράσει ο τελικός καταναλωτής.</li>
          </ol>
        </p>

        <p>
          <h3>ΟΔΗΓΙΕΣ ΚΑΙ ΕΦΑΡΜΟΓΗ ΤΗΣ ΠΡΟΩΘΗΤΙΚΗΣ ΕΝΕΡΓΕΙΑΣ TYRELIFE</h3>
          <p>Η PIRELLI δεν είναι σε καμία περίπτωση υπεύθυνη για τις ενέργειες των μεταπωλητών, οι οποίο ενεργούν σε πλήρη αυτονομία και με πλήρη ανάληψη ευθύνης σε σχέση με τις δραστηριότητες που θα πραγματοποιήσουν στα πλαίσια της ενέργειας Tyrelife.</p>
        </p>
    `
  }
}
