import Vue      from 'vue'
import Router   from 'vue-router'
import { i18n } from '@/lang/lang'
// Views
import Home     from './views/home/Home'
import Step1    from './views/home/Step1'
import Step2    from './views/home/Step2'
import Step3    from './views/home/Step3'
/* import PlaceHolder from './views/PlaceHolder' */

Vue.use(Router)

const routes = [
  {
    path     : '/:lang',
    props    : true,
    component: {
      render (c) {
        return c('router-view')
      }
    },
    children : [
      {
        name     : 'Home',
        path     : '/',
        component: Home,
        meta     : {
          layout: 'home'
        }
      },
      {
        name     : 'Step1',
        path     : 'step-1',
        component: Step1,
        props    : route => ({ item: route.params.item }),
        meta     : {
          layout: 'questions'
        }
      },
      {
        name     : 'Step2',
        path     : 'step-2',
        component: Step2,
        props    : route => ({ item: route.params.item }),
        meta     : {
          layout: 'questions'
        }
      },
      {
        name     : 'Step3',
        path     : 'step-3',
        component: Step3,
        meta     : {
          layout: 'questions'
        }
      }
    ]
  },

  {
    name    : 'Default',
    path    : '*',
    redirect: '/' + i18n.locale
  }
]

if (window.location.href.endsWith('//')) {
  window.location.href = window.location.href.replace(/\/+$/, '')
}

const createRouter = () => new Router({
  mode  : 'history',
  base  : process.env.BASE_URL,
  routes: routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {
        x: 0,
        y: 0
      }
    }
  }
})

const router = createRouter()

router.beforeEach((to, from, next) => {
  const lang = to.params.lang
  const langExists = !!i18n.languages.find(l => l.locale === lang)

  if (langExists) {
    next()
  } else {
    next({ replace: true, name: to.matched.length > 0 ? to.name : 'Default', params: { lang: process.env.VUE_APP_I18N_LOCALE } })
  }
})

const routerPush = Router.prototype.push
const routerReplace = Router.prototype.replace
Router.prototype.push = function push (location) {
  return routerPush.call(this, location).catch(error => error)
}
Router.prototype.replace = function replace (location) {
  return routerReplace.call(this, location).catch(error => error)
}

export function resetRouter () {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher
  window.isVueRoutesInited = false
}

export default router
