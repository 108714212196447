export default {
  name: 'ReviewModel',

  data () {
    return {
      model: {
        DealerId: null,
        Q1      : '',
        Q2      : '',
        Q3      : '',
        Q4      : '',
        Q5      : '',
        Q6      : '',
        Q7      : '',
        Q8      : '',
        Terms   : false
      },

      validationRules: {
        DealerId: { required: true },
        Q1      : { required: true },
        Q2      : { required: true },
        Q3      : { required: true },
        Q4      : { required: true },
        Q5      : { required: true },
        Q6      : { required: true },
        Q7      : { required: true },
        Q8      : { required: false, type: 'string', minLen: 10 },
        Terms   : { required: false, type: 'boolean', equalsValueTerms: v => v === true }
      },

      validationErrorMessages: {
        el: {
          equalsValueTerms: 'Πρέπει να αποδεχτείτε τους όρους χρήσης'
        },
        en: {
          equalsValueTerms: 'You must accept the terms of use'
        }
      }

    }
  }

}
