<template>
  <v-row justify="center">
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xs"
      max-width="1024px"
      persistent
      scrollable
    >
      <v-card>
        <v-toolbar
          color="secondary"
          flat
        >
          <div
            :class="[`${color}--text v-alert__border--has-color`]"
            class="v-alert__border v-alert__border--left"
          />

          <v-avatar
            v-if="icon"
            :color="color"
            class="mr-2"
            size="36"
          >
            <v-icon color="grey">
              {{ icon }}
            </v-icon>
          </v-avatar>

          <v-divider
            v-if="icon"
            class="mr-2"
            vertical
          />

          <v-toolbar-title v-if="title || subTitle">
            <div
              v-if="title"
              :class="[`${color}--text`]"
              class="subtitle-1"
            >
              {{ title }}
            </div>
            <div
              v-if="subTitle"
              class="caption grey--text text--lighten-1"
            >
              {{ subTitle }}
            </div>
          </v-toolbar-title>

          <v-spacer />

          <v-btn
            dark
            icon
            @click.native="closeDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider />

        <v-card-text ref="vCardText">
          <div
            class="mt-6"
            v-html="body"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import DialogVisible from '../../mixins/dialogVisible'

export default {
  name      : 'TermsViewDialog',
  components: {},
  directives: {},
  mixins    : [DialogVisible],
  props     : {},
  data () {
    return {
      color   : 'darken-1 grey',
      icon    : 'mdi-album',
      title   : this.$t('Home.Terms.Title'),
      subTitle: this.$t('Home.Terms.SubTitle'),
      body    : this.$t('Home.Terms.Body')
    }
  },
  computed: {},
  watch   : {
    isVisible (newVal) {
      if (!newVal && this.$refs.vCardText) this.$refs.vCardText.scrollTop = 0
    }
  },
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {}
}
</script>

<style scoped>

</style>
